<template>
    <div class="g-block g-block--medium">
        <h3>
            Facebook paid conversions
            <div class="conversion-rate conversion-rate--large conversion-rate--right" v-bind:class="getComponents.paidSocial.FacebookConversionsBlock.paidConversionClass">
                <div class="grid-container">
                    <div class="col"><p>{{ formatPercentage(getComponents.paidSocial.FacebookConversionsBlock.runningTotalPrevious) }}</p></div>
                    <div class="col"><span class="arrow"><i class="fa-solid fa-arrow-up-long"></i></span></div>
                </div>
            </div>
		</h3>
        <span class="g-block__num" style="height: 0;">{{ getComponents.paidSocial.FacebookConversionsBlock.totalPaidConversions }}</span>
        <div class="g-lines">
            <apexchart ref="organicSessionsChart" height="145" type="area" :options="chartOptions" :series="chartSeries"></apexchart>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex"

export default {
    computed: {
        ...mapGetters("client", ["getClientSlug", "getSocial", "getComponents"]),
    },
    async mounted(){
        try{
            await this.init(this.getSocial.tokens.google);
        }catch(error){
            console.log('mounted error catch', error);
        }
    },
    watch:{
        'getSocial.tokens.google': {
        deep: true,
            async handler(newValue) {
                try{
                    await this.init(newValue);
                }catch(error){
                    console.log('watch error catch', error);
                }
            }
        }
    },
    methods:{
        async init(data){
            try{
                    const GA4PropertyID = data.GA4PropertyID;
                    const propertyID = data.accountID;
                    const accessToken = data.accessToken;
                    if(accessToken && GA4PropertyID && propertyID){
                        const response = await this.$store.dispatch('client/facebookConversionBlockInit');
                        this.displayData(response);

                    }
                }catch(error){
                    console.log('init error catch', error);
                }
        },
        displayData(updatedGoogleData){

            let dailyHits = []
            let dailyLabels = []
            const metricClean = 'socialSessionsByDay';
            const momDays = 56;
            let runningTotal = this.getComponents.paidSocial.FacebookConversionsBlock.runningTotal;
            let runningTotalPrevious = this.getComponents.paidSocial.FacebookConversionsBlock.runningTotalPrevious;

            for (var j = 28; j > 0; j--) {

                var savedDate = new Date();
                savedDate.setDate(savedDate.getDate() - j);
                let thedate = new Date(savedDate);
                savedDate = savedDate.toISOString().slice(0, 10);
                let currentDaysHits = updatedGoogleData.metrics[metricClean].facebook[savedDate]

                if (Number.isInteger(parseInt(currentDaysHits))) {
                    dailyHits.push(currentDaysHits)
                    runningTotal = runningTotal + parseInt(currentDaysHits);
                    let mon = thedate.toLocaleString('en-UK', {month: 'short'});
                    let dd = thedate.getDate()
                    dailyLabels.push(mon + ' ' + dd)
                }

                this.$refs.organicSessionsChart.updateOptions({
                    series: [{
                        name: 'Hits',
                        data: dailyHits,
                    }],
                    xaxis: {
                        categories: dailyLabels,
                    },
                    })
            }

            for (var k = momDays; k > 28; k--) {

                var savedDateLastMonth = new Date();
                savedDateLastMonth.setDate(savedDateLastMonth.getDate() - k);
                savedDateLastMonth = savedDateLastMonth.toISOString().slice(0, 10);
                let currentDaysHits = updatedGoogleData.metrics[metricClean].facebook[savedDate]
                runningTotalPrevious = runningTotalPrevious + parseInt(currentDaysHits)
            }
            this.$store.commit('client/setComponents', {prop: 'paidSocial/FacebookConversionsBlock/totalPaidConversions', val: runningTotal});
            this.$store.commit('client/setComponents', {prop: 'paidSocial/FacebookConversionsBlock/totalPaidConversionsPrevious', val: (((parseFloat(runningTotal) / runningTotalPrevious) * 100) - 100).toFixed(1)});
            this.$store.commit('client/setComponents', {prop: 'paidSocial/FacebookConversionsBlock/paidConversionClass', val: this.conversionRate(this.getComponents.paidSocial.FacebookConversionsBlock.totalPaidConversionsPrevious)});

            this.componentHasAllData();
            
        },
        componentHasAllData(){

            let vue = '';

            if(this.$route.name === 'Paid Social'){

                vue = 'paidSocialComponents';

            }if(this.$route.name === 'Organic Social'){

                vue = 'organicSocialComponents';

            }

            let newVal = {
                vue: vue,
                component: 'FacebookConversionsBlock',
                val: true
            }

            this.$store.commit('client/setPageLoaded', newVal);

        },
    },
    data() {
        return {
            chartSeries: [],
            chartOptions: {
                chart: {
                    id: 'organicSessionsChart',
                    type: 'area',
                    height: 145,
                    animations: {
                        enabled: false,
                    },
                    toolbar: {
                        show: false,
                    },
                    sparkline: {
                        enabled: true,
                    },
                    selection: {
                        enabled: false,
                    },
                },
                colors: ['#BC8FF8'],
                fill: {
                    gradient: {
                        enabled: true,
                        opacityFrom: 0.35,
                        opacityTo: 0
                    }
                },
                dataLabels: {
                    enabled: false
                },
                grid: {
                    borderColor: '#4F516E',
                },
                stroke: {
                    width: 2,
                    lineCap: 'round',
                    curve: 'smooth'
                },
                tooltip: {
                    theme: false,
                    custom: function({series, seriesIndex, dataPointIndex, w}) {
                        return '<div class="tooltip">' +
                        '<span class="tooltip__index"> ' + w.globals.categoryLabels[dataPointIndex] + '</span>' +
                        '<span class="tooltip__num"> ' + series[seriesIndex][dataPointIndex] + '</span>' +
                        '</div>'
                    },
                },
                noData: {
                  text: 'Loading...'
                }
            },
        }
    },
}

</script>
