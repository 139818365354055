<template>
    <div>
        <div class="g-social g-social--paid">
        <header class="g-header">
            <h2>Paid Social</h2>
        </header>
        <div class="g-graph">
            <div class="g-graph__header">
                <p class="sessions">Google Analytics sessions</p>
                <p class="number">{{ getComponents.paidSocial.HeaderGraphBlock.paidSessionsVal }}</p>
                <div class="conversion-rate conversion-rate--large" v-bind:class="getComponents.paidSocial.HeaderGraphBlock.paidConversionClass">
                    <div class="grid-container">
                        <div class="col">
                            <p>{{ getComponents.paidSocial.HeaderGraphBlock.paidConversionAmount }}</p>
                        </div>
                        <div class="col">
                            <span class="arrow">
                                <i class="fa-solid fa-arrow-up-long"></i>
                            </span>
                        </div>
                    </div>
                </div>
                <p class="suffix">than last month</p>
            </div>
            <apexchart ref="paidChart" height="320" type="area" :options="paidChartOptions" :series="paidSeries"></apexchart>
        </div>
    </div>
    <div class="g-social g-social--organic">
        <header class="g-header">
            <h2>Organic Social</h2>
        </header>
        <div class="g-graph">
            <div class="g-graph__header">
                <p class="sessions">Google Analytics sessions</p>
                <p class="number">{{ getComponents.paidSocial.HeaderGraphBlock.organicSessionsVal }}</p>
                <div class="conversion-rate conversion-rate--large" v-bind:class="getComponents.paidSocial.HeaderGraphBlock.organicConversionClass">
                    <div class="grid-container">
                        <div class="col">
                            <p>{{ getComponents.paidSocial.HeaderGraphBlock.organicConversionAmount }}</p>
                        </div>
                        <div class="col">
                            <span class="arrow">
                                <i class="fa-solid fa-arrow-up-long"></i>
                            </span>
                        </div>
                    </div>
                </div>
                <p class="suffix">than last month</p>
            </div>
            <apexchart ref="organicChart" height="320" type="area" :options="organicChartOptions" :series="organicSeries"></apexchart>
        </div>
    </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
// import PaidChartOptions from '../../services/contentNodes';

export default {
    computed: {
        ...mapGetters("client", ["getClientSlug", "getSocial", "getComponents"]),
    },
    watch:{
        'getSocial.tokens.google': {
        deep: true,
            async handler(newValue) {
                try{
                    await this.init(newValue);
                }catch(error){
                    console.log('watch error catch', error);
                }
            }
        }
    },
    async mounted(){
        try{
            await this.init(this.getSocial.tokens.google);
        }catch(error){
            console.log('mounted error catch', error);
        }
    },
    methods:{
        async init(data){
            try{
                    const GA4PropertyID = data.GA4PropertyID;
                    const propertyID = data.accountID;
                    const accessToken = data.accessToken;
                    if(accessToken && GA4PropertyID && propertyID){
                        const response = await this.$store.dispatch('client/headerGraphBlockInit');
                        this.displayData(response);

                    }
                }catch(error){
                    console.log('init error catch', error);
                }
        },
        displayData(updatedGoogleData){

            let dailyHitsPaid = []
            let dailyHitsOrganic = []
            let dailyLabelsPaid = []
            let dailyLabelsOrganic = []
            const metricClean = 'sessionsByDay';

            for (var j = 30; j > 0; j--) {
                
                var savedDate = new Date();
                savedDate.setDate(savedDate.getDate() - j);
                let thedate = new Date(savedDate);
                savedDate = savedDate.toISOString().slice(0, 10);
                let paidDaysHits = updatedGoogleData.metrics[metricClean].paid[savedDate]
                let organicDaysHits = updatedGoogleData.metrics[metricClean].organic[savedDate]

                // Check that the current hits amount is a number, incase it's not in the list for any reason.
                if (Number.isInteger(paidDaysHits)) {
                    dailyHitsPaid.push(paidDaysHits)
                    let mon = thedate.toLocaleString('en-UK', {month: 'short'});
                    let dd = thedate.getDate()
                    dailyLabelsPaid.push(mon + ' ' + dd);

                    this.$refs.paidChart.updateOptions({
                        series: [{
                            name: 'Hits',
                            data: dailyHitsPaid,
                        }],
                        xaxis: {
                            categories: dailyLabelsPaid,
                        },
                    })

                }
                if (Number.isInteger(organicDaysHits)) {

                    dailyHitsOrganic.push(organicDaysHits)
                    let mon = thedate.toLocaleString('en-UK', {month: 'short'});
                    let dd = thedate.getDate()
                    dailyLabelsOrganic.push(mon + ' ' + dd);

                    this.$refs.organicChart.updateOptions({
                        series: [{
                            name: 'Hits',
                            data: dailyHitsOrganic,
                        }],
                        xaxis: {
                            categories: dailyLabelsOrganic,
                        },
                    })

                }

            }

            this.componentHasAllData();

        },
        componentHasAllData(){

            let vue = '';

            if(this.$route.name === 'Paid Social'){

                vue = 'paidSocialComponents';

            }if(this.$route.name === 'Organic Social'){

                vue = 'organicSocialComponents';

            }

            let newVal = {
                vue: vue,
                component: 'HeaderGraphBlock',
                val: true
            }

            this.$store.commit('client/setPageLoaded', newVal);

        },
    },
    data(){
        return{
            paidSeries: [],
            paidChartOptions: {
                chart: {
                    id: 'paidChart',
                    type: 'area',
                    height: 320,
                    animations: {
                        enabled: false,
                    },
                    toolbar: {
                        show: false,
                    },
                    selection: {
                        enabled: false,
                    },
                },
                colors: ['#BC8FF8'],
                fill: {
                    gradient: {
                        enabled: true,
                        opacityFrom: 0.35,
                        opacityTo: 0
                    }
                },
                dataLabels: {
                    enabled: false
                },
                grid: {
                    borderColor: '#4F516E',
                },
                yaxis: {
                    floating: true,
                    forceNiceScale: true,
                    min: 0,
                    labels: {
                        style: {
                            colors: ['#ffffff'],
                        },
                        offsetX: 40,
                    },
                },
                xaxis: {
                    axisBorder: {
                        color: '#4F516E',
                    },
                    axisTicks: {
                        show: false,
                    },
                    crosshairs: {
                        show: false,
                    },
                    labels: {
                        show: false,
                    },
                    tooltip: {
                        enabled: false,
                    },
                },
                stroke: {
                    width: 2,
                    lineCap: 'round',
                    curve: 'smooth'
                },
                tooltip: {
                    theme: false,
                    custom: function({series, seriesIndex, dataPointIndex, w}) {
                        return '<div class="tooltip">' +
                        '<span class="tooltip__index"> ' + w.globals.categoryLabels[dataPointIndex] + '</span>' +
                        '<span class="tooltip__num"> ' + series[seriesIndex][dataPointIndex] + '</span>' +
                        '</div>'
                    },
                },
                noData: {
                text: 'Loading...'
                }
            },
            organicSeries: [],
            organicChartOptions: {
                chart: {
                    id: 'organicChart',
                    type: 'area',
                    height: 320,
                    animations: {
                        enabled: false,
                    },
                    toolbar: {
                        show: false,
                    },
                    selection: {
                        enabled: false,
                    },
                },
                colors: ['#FF8344'],
                fill: {
                    gradient: {
                        enabled: true,
                        opacityFrom: 0.35,
                        opacityTo: 0
                    }
                },
                dataLabels: {
                    enabled: false
                },
                grid: {
                    borderColor: '#4F516E',
                },
                yaxis: {
                    floating: true,
                    forceNiceScale: true,
                    min: 0,
                    labels: {
                        style: {
                            colors: ['#ffffff'],
                        },
                        offsetX: 40,
                    },
                },
                xaxis: {
                    axisBorder: {
                        color: '#4F516E',
                    },
                    axisTicks: {
                        show: false,
                    },
                    crosshairs: {
                        show: false,
                    },
                    labels: {
                        show: false,
                    },
                    tooltip: {
                        enabled: false,
                    },
                },
                stroke: {
                    width: 2,
                    lineCap: 'round',
                    curve: 'smooth'
                },
                tooltip: {
                    theme: false,
                    custom: function({series, seriesIndex, dataPointIndex, w}) {
                        return '<div class="tooltip">' +
                        '<span class="tooltip__index"> ' + w.globals.categoryLabels[dataPointIndex] + '</span>' +
                        '<span class="tooltip__num"> ' + series[seriesIndex][dataPointIndex] + '</span>' +
                        '</div>'
                    },
                },
                noData: {
                text: 'Loading...'
                }
            }
        }
    },
}
</script>
