<template>
    <div class="g-block g-block--large">
        <h3>Conversions</h3>
        <div class="g-donut">
            <p class="g-donut__num">{{ getComponents.paidSocial.ConversionsBlock.conversionsTotal }}</p>
            <svg class="pseudo-hide">
                <defs>
                    <radialGradient id="conversionsChartBG">
                        <stop offset="0%" stop-color="rgba(32, 35, 63, 0)"/>
                        <stop offset="100%" stop-color="rgba(42, 187, 238, .2)"/>
                    </radialGradient>
                </defs>
            </svg>
			<apexchart ref="conversionsChart" type="donut" height="245px" :options="chartOptions" :series="conversionsSeries" v-if="conversionsSeries"></apexchart>
			<div class="g-pie-legend" v-bind:style="'--numberOfLegendItems:' + getComponents.paidSocial.ConversionsBlock.numberOfLegendItems">
				<ul>
					<li v-for="(label, index) in getComponents.paidSocial.ConversionsBlock.legendLabels" :key="label" :data-tooltip="label" @mouseover="hover = index" @mouseleave="hover = null" :class="{ active: hover }">
						<span class="dot"></span><div v-html="label"></div>
						<b v-if="hover === index" v-html="label"></b>
					</li>
				</ul>
			</div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex"

// Note: chart colours also used in google.scss
const chartColors = ['#18B28C', '#FF8344', '#2ABBEE', '#177DC0', '#F6C844', '#BC8FF8', '#FF50A1', '#F75041'];

export default {
    computed: {
        ...mapGetters("client", ["getClientSlug", "getSocial", "getComponents"]),
    },
    async mounted(){
        try{
            await this.init(this.getSocial.tokens.google);
        }catch(error){
            console.log('mounted error catch', error);
        }
    },
    watch:{
        'getSocial.tokens.google': {
        deep: true,
            async handler(newValue) {
                try{
                    await this.init(newValue);
                }catch(error){
                    console.log('watch error catch', error);
                }
            }
        }
    },
    methods:{
        async init(data){
            try{
                const GA4PropertyID = data.GA4PropertyID;
                const propertyID = data.accountID;
                const accessToken = data.accessToken;
                if(accessToken && GA4PropertyID && propertyID){
                    const response = await this.$store.dispatch('client/conversionsBlockInit');
                    this.displayData(response);

                }
                }catch(error){
                    console.log('init error catch', error);
                }
        },
        displayData(updatedGoogleData){
          const numberData = JSON.parse(updatedGoogleData.metrics['goalsFacebook'].goalValues).map(Number);
          this.conversionsSeries = numberData;
          this.componentHasAllData();
        },
        componentHasAllData(){
            let vue = '';
            if(this.$route.name === 'Paid Social'){
                vue = 'paidSocialComponents';
            }if(this.$route.name === 'Organic Social'){
                vue = 'organicSocialComponents';
            }
            let newVal = {
                vue: vue,
                component: 'ConversionsBlock',
                val: true
            }
            this.$store.commit('client/setPageLoaded', newVal);
        },
    },
    data() {
        return {
            hover: null,
			      numberOfLegendItems: '',
            conversionsSeries: '',
            updatedGoogleData: '',
            conversionsTotal: '',
            legendLabels: '',
            promises : [],
            chartOptions: {
              chart: {
                type: 'donut',
                animations: {
                  enabled: false,
                },
                toolbar: {
                  show: false,
                },
                foreColor: '#8487A7',
                selection: {
                  enabled: false,
                },
                
              },
              height: '100%',
              colors: chartColors,
              dataLabels: {
                enabled: false,
              },
              // Note: not using the legend in apex charts as it's impossible to style 
              legend: {
                show: false,
              },
              stroke: {
                lineCap: 'round',
                colors: chartColors,
                width: 16,
              },
              tooltip: {
                style: {
                  fontSize: '14px',
                  fontFamily: 'Gilroy',
                },
              },
              plotOptions: {
                pie: {
                  expandOnClick: false,
                  donut: {
                    size: '100%',
                  },
                },
              },
              noData: {
                text: 'Loading...',
              },
            },
        }
    },
}

</script>
